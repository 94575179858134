//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ImportProgress',
  props: {
    maxValue: {
      type: Number,
      requred: true,
    },
    value: {
      type: Number,
      requred: true,
    },
  },
  computed: {
    progressPercent () {
      return (this.value / this.maxValue * 100).toFixed()
    },
  },
}
